import styles from './styles.module.scss';
import { clsx } from 'clsx';
import { useCallback } from 'react';
import { DefaultEmbedProviders } from '../../../ui/form/editor/plugins/embed';
import { FiLoader } from 'react-icons/fi';
import { fixWordBreaks } from '../../helpers';
import { escape } from 'lodash';

type Props = {
  className?: string;
  input: string;
  provider: string;
  html: string;
  caption?: string;
};

export function Embed(props: Props) {
  const { className, input, provider, html, caption } = props;
  const preparedCaption = caption && fixWordBreaks(escape(caption));
  const embedProvider = DefaultEmbedProviders[provider];

  const onRef = useCallback(
    (elem: HTMLDivElement | null) => {
      if (!elem) {
        return;
      }

      if (embedProvider) {
        embedProvider(input)
          .then((result) => {
            if (result) {
              elem.innerHTML = result.html + elem.innerHTML;
            }
          })
          .catch(console.error);
      } else {
        elem.innerHTML = html + elem.innerHTML;
      }
    },
    [html, input, embedProvider],
  );

  const providerClasses: Record<string, string | undefined> = {
    instagram: styles['embed--provider-instagram'],
    twitter: styles['embed--provider-twitter'],
  };

  return (
    <div
      data-provider={provider}
      data-provider-input={input}
      className={clsx(className, styles['embed'], providerClasses[provider])}
    >
      <div className={clsx(styles['embed__container'])} ref={onRef}>
        <div className={styles['embed__loader']}>
          <FiLoader size={'1em'} />
        </div>
        {preparedCaption && (
          <div
            className={styles['embed__caption']}
            dangerouslySetInnerHTML={{ __html: preparedCaption }}
          />
        )}
      </div>
    </div>
  );
}
